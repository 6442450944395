<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!--상세-->
        <c-card class="cardClassDetailForm" title="LBLDETAIL">
          <template slot="card-button">
            <q-btn-group outline >
              <!--삭제-->
              <c-btn v-if="editable&&!disabled&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteOuternal" />
              <!--저장-->
              <c-btn 
                v-if="editable && !disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="outernal"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveOuternal"
                @btnCallback="saveOuternalCallback" />
              <!-- [S]결재관련 버튼(그룹웨어 연동) -->
              <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
              <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
              <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
              <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
              <!-- <c-appr-gw-btn 
                ref="appr-btn"
                name="work-permit-appr-btn"
                :editable="editable"
                :approvalInfo="approvalInfo"
                @beforeApprAction="approvalWorkPermit"
                @callbackApprAction="approvalWorkPermitCallback"
                @requestAfterAction="getDetail('save')"
              /> -->
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--외부심사명-->
              <c-text
                :required="true"
                :disabled="disabled"
                :editable="editable"
                label="LBL0010164"
                name="outernalResultName"
                v-model="outernal.outernalResultName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text
                :disabled="disabled"
                :editable="editable"
                label="심사기관"
                name="saiOuternalAuditAgencyEtc"
                v-model="outernal.saiOuternalAuditAgencyEtc">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :required="true"
                :disabled="disabled"
                :editable="editable"
                default="datetime"
                :minuteStep="10"
                label="심사일시"
                name="period"
                v-model="outernal.auditStartDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :disabled="disabled"
                :editable="editable"
                name="plantCd"
                v-model="outernal.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-dept-multi 
                type="edit" 
                :disabled="disabled"
                :editable="editable"
                :isFirstValue="false" 
                label="피심사부서" 
                :required="true" 
                name="auditTargetDeptCd" 
                v-model="outernal.auditTargetDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :disabled="disabled"
                :editable="editable"
                label="심사결과"
                name="auditResultSummary"
                v-model="outernal.auditResultSummary">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!--심사목적-->
              <c-textarea
                :disabled="disabled"
                :editable="editable"
                :rows="2"
                label="LBL0010171"
                name="auditPurpose"
                v-model="outernal.auditPurpose">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="inTable"
          title="피심사참석자(내부)"
          :columns="gridTeam.columns"
          :data="outernal.inAuditTeams"
          :gridHeight="gridTeam.height"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiOuternalResultAuditTeamId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addInUser" />
              <!--제외-->
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removeInUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="outTable"
          title="피심사참석자(외부)"
          :columns="gridTeam2.columns"
          :data="outernal.outAuditTeams"
          :gridHeight="gridTeam2.height"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiOuternalResultAuditTeamId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addOutUser" />
              <!--제외-->
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removeOutUser" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="pointOutTable"
          title="심사 지적내용"
          :columns="girdPointOut.columns"
          :data="outernal.pointOuts"
          :gridHeight="girdPointOut.height"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiOuternalResultAuditTeamId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <!--추가-->
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addPointOut" />
              <!--제외-->
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removePointOut" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'outernal-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiOuternalResultId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      outernal: {
        saiOuternalResultId: '',  // 외부심사결과 일련번호
        plantCd: '',  // 사업장코드
        outernalResultName: '',  // 외부심사명
        auditStartDate: '',  // 심사시작일
        auditEndDate: '',  // 심사종료일
        period: [],
        year: '',
        auditTargetDeptCd: '',  // 심사대상부서
        auditDeptCd: '',  // 심사주관부서
        saiOuternalAuditAgency: null,  // 주관심사기관
        saiOuternalAuditAgencyEtc: '',  // 주관심사기관 기타
        auditPurpose: '',  // 심사목적
        imprDerivedItems: '',  // 개선도출항목
        auditResult: null,  // 심사결과
        auditResultSummary: '',  // 심사결과요약
        auditTargetDeptReviewOpinion: '',  // 심사대상부서검토의견
        regUserId: '',
        chgUserId: '',
        inAuditTeams: [], // 내부심사팀
        deleteInAuditTeams: [], // 내부심사팀 [삭제]
        outAuditTeams: [], // 외부심사팀
        deleteOutAuditTeams: [], // 외부심사팀 [삭제]

        pointOuts: [],
        deletePointOuts: [],
      },
      disabled: false,
      gridTeam: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            //부서
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            //이름
            label: 'LBLNAME',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            //직책
            label: 'LBLPOSITION',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      gridTeam2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            //소속
            label: 'LBL0000292',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:30%',
          },
          {
            name: 'userName',
            field: 'userName',
            //이름
            label: 'LBLNAME',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:40%',
          },
          {
            name: 'jobName',
            field: 'jobName',
            //직책
            label: 'LBLPOSITION',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:30%',
          },
        ],
        data: [],
        height: '300px',
      },
      girdPointOut: {
        columns: [
          {
            required: true,
            name: 'deptName',
            field: 'deptName',
            label: '시정부서',
            align: 'left',
            type: 'dept',
            deptCd: 'deptCd',
            sortable: false,
            style: 'width:150px',
          },
          {
            required: true,
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'correctiveOrder',
            field: 'correctiveOrder',
            label: '시정명령',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:300px',
          },
          {
            name: 'actionPlan',
            field: 'actionPlan',
            label: '조치계획',
            align: 'left',
            type: 'text',
            sortable: false,
            style: 'width:300px',
          },
          {
            name: 'relatedLaws',
            field: 'relatedLaws',
            label: '관련법규',
            align: 'left',
            type: "multiSelect",
            itemText: 'codeName',
            itemValue: 'code',
            isArray: false,
            sortable: false,
            style: 'width:200px',
            codeGroupCd: 'RELATED_LAWS_CD',
          },
        ],
        data: [],
        height: '300px',
      },
      editable: true,
      isSave: false,
      saveUrl: '', 
      deleteUrl: '', 
      approvalUrl: '',
      isApproval: false,
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      gwDatakey: '',
      gwStatus: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // [E] 결재관련 버튼 컨트롤
    approvalInfo() {
      return {
        sysApprovalRequestId: this.outernal.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.outernal.approvalStatusCd, 
        apprEditable: this.isOld 
          && Boolean(!this.outernal.sysApprovalRequestId), // 결재버튼 활성화 기준
        param: this.outernal, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000001', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiOuternalResultId: this.outernal.saiOuternalResultId,
          isApprContent: true
        },
        approvalRequestName: this.outernal.outernalResultName, // 결재요청명 (문서 title)
        approvalConnId: this.outernal.saiOuternalResultId, // 결재연결용 업무일련번호 (문서 key)
        approvalFormCode: 'ESF-SWP', // 그룹웨어상 양식코드
        approvalFormTitle: '외부심사',  // 그룹웨어상 양식명
        gwDatakey: this.gwDatakey,
        gwStatus: this.gwStatus,
      }
    },
    isOld() {
      return Boolean(this.popupParam.saiOuternalResultId)
    },
    innerTeams() {
      return this.$_.filter(this.outernal.auditTeams, { comInsideOutsideCd: '1' })
    },
    outTeams() {
      return this.$_.filter(this.outernal.auditTeams, { comInsideOutsideCd: '2' })
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.outernal.result.get.url;
      this.saveUrl = transactionConfig.sai.outernal.result.insert.url;
      this.approvalUrl = transactionConfig.sai.outernal.result.update.url;
      this.deleteUrl = transactionConfig.sai.outernal.result.delete.url;

      this.getDetail();
      // this.getGwApprInfo();
    },
    getDetail() {
      if (this.popupParam.saiOuternalResultId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiOuternalResultId);
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.$_.extend(this.outernal, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } 
    },
    getGwApprInfo() { // 그룹웨어 결재요청 정보
      if (this.popupParam.saiOuternalResultId) {
        this.$http.url = selectConfig.sys.gwappr.appr.get.url;
        this.$http.type = 'GET';
        this.$http.param = {
          docid: this.popupParam.saiOuternalResultId
        };
        this.$http.request((_result) => {
          if (_result.data.datakey) {
            this.gwDatakey = _result.data.datakey;
            this.gwStatus = _result.data.datastatus;
          }
        },);
      }
    },
    addInUser() {
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.outernal.inAuditTeams, { userId: item.userId }) === -1) {
            _data.push({
              saiOuternalResultAuditTeamId: uid(),  // 외부심사결과 심사팀 일련번호
              saiOuternalResultId: this.popupParam.saiOuternalResultId,  // 외부심사결과 일련번호
              plantName: item.plantName,  // 사업장
              deptName: item.deptName,  // 부서(소속)
              jobName: item.jobName,  // 직책
              userName: item.userName,  // 심사팀
              userId: item.userId,  // 심사팀id
              comInsideOutsideCd: 'OAT0000001',  // 내외부 구분
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        this.outernal.inAuditTeams = this.$_.concat(this.outernal.inAuditTeams, _data)
      }
    },
    addOutUser() {
      this.outernal.outAuditTeams.splice(0, 0, {
        saiOuternalResultAuditTeamId: uid(),  // 외부심사결과 심사팀 일련번호
        saiOuternalResultId: this.popupParam.saiOuternalResultId,  // 외부심사결과 일련번호
        plantName: '',  // 사업장
        deptName: '',  // 부서(소속)
        jobName: '',  // 직책
        userName: '',  // 심사팀
        userId: '',  // 심사팀id
        comInsideOutsideCd: 'OAT0000005',  // 내외부 구분
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    removeInUser() {
      let selectData = this.$refs['inTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.outernal.deleteInAuditTeams) {
            this.outernal.deleteInAuditTeams = [];
          }
          if (
            this.$_.findIndex(this.outernal.deleteInAuditTeams, {
              saiOuternalResultAuditTeamId: item.saiOuternalResultAuditTeamId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.outernal.deleteInAuditTeams.push(item);
          }
          this.outernal.inAuditTeams = this.$_.reject(
            this.outernal.inAuditTeams,
            item
          );
        });
        this.$refs['inTable'].$refs['compo-table'].clearSelection();
      }
    },
    removeOutUser() {
      let selectData = this.$refs['outTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.outernal.deleteOutAuditTeams) {
            this.outernal.deleteOutAuditTeams = [];
          }
          if (
            this.$_.findIndex(this.outernal.deleteOutAuditTeams, {
              saiOuternalResultAuditTeamId: item.saiOuternalResultAuditTeamId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.outernal.deleteOutAuditTeams.push(item);
          }
          this.outernal.outAuditTeams = this.$_.reject(
            this.outernal.outAuditTeams,
            item
          );
        });
        this.$refs['outTable'].$refs['compo-table'].clearSelection();
      }
    },
    addPointOut() {
      this.outernal.pointOuts.splice(0, 0, {

        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    removePointOut() {
      let selectData = this.$refs['pointOutTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.outernal.deletePointOuts) {
            this.outernal.deletePointOuts = [];
          }
          if (
            this.$_.findIndex(this.outernal.deletePointOuts, {
              saiOuternalResultPointOutId: item.saiOuternalResultPointOutId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.outernal.deletePointOuts.push(item);
          }
          this.outernal.pointOuts = this.$_.reject(
            this.outernal.pointOuts,
            item
          );
        });
        this.$refs['pointOutTable'].$refs['compo-table'].clearSelection();
      }
    },
    saveOuternal() {
      if (this.popupParam.saiOuternalResultId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.outernal.result.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.outernal.result.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.outernal.regUserId = this.$store.getters.user.userId
              this.outernal.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveOuternalCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'saiOuternalResultId', _result.data)
      this.getDetail();
    },
    deleteOuternal() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiOuternalResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalWorkPermit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.outernal.regUserId = this.$store.getters.user.userId
              this.outernal.chgUserId = this.$store.getters.user.userId
              
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    // [S] 결재관련 
    approvalWorkPermitCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>